import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject, NgModule } from '@angular/core';
import * as i2 from '@angular/router';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { of, tap, delay, filter, switchMap } from 'rxjs';
import * as i1 from 'ngx-progressbar';
const NG_PROGRESS_ROUTER_CONFIG = new InjectionToken('ngProgressRouterConfig');

/**
 * Check if a router event type exists in an array of router event types
 */
function eventExists(routerEvent, events) {
  let res = false;
  events.map(event => res = res || routerEvent instanceof event);
  return res;
}
class NgProgressRouter {
  constructor(progress, router, config) {
    this._config = {
      id: 'root',
      delay: 0,
      startEvents: [NavigationStart],
      completeEvents: [NavigationEnd, NavigationCancel, NavigationError]
    };
    this._config = config ? {
      ...this._config,
      ...config
    } : this._config;
    const progressRef = progress.ref(this._config.id);
    const startProgress = of({}).pipe(tap(() => progressRef.start()));
    const completeProgress = of({}).pipe(delay(this._config.delay), tap(() => progressRef.complete()));
    const filterEvents = [...this._config.startEvents, ...this._config.completeEvents];
    router.events.pipe(filter(event => eventExists(event, filterEvents)), switchMap(event => eventExists(event, this._config.startEvents) ? startProgress : completeProgress)).subscribe();
  }
  static {
    this.ɵfac = function NgProgressRouter_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgProgressRouter)(i0.ɵɵinject(i1.NgProgress), i0.ɵɵinject(i2.Router), i0.ɵɵinject(NG_PROGRESS_ROUTER_CONFIG, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgProgressRouter,
      factory: NgProgressRouter.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressRouter, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i1.NgProgress
    }, {
      type: i2.Router
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [NG_PROGRESS_ROUTER_CONFIG]
      }]
    }];
  }, null);
})();
class NgProgressRouterModule {
  // Inject the service to activate it
  constructor(ngProgressRouter) {}
  static withConfig(config) {
    return {
      ngModule: NgProgressRouterModule,
      providers: [{
        provide: NG_PROGRESS_ROUTER_CONFIG,
        useValue: config
      }]
    };
  }
  static {
    this.ɵfac = function NgProgressRouterModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NgProgressRouterModule)(i0.ɵɵinject(NgProgressRouter));
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgProgressRouterModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgProgressRouterModule, [{
    type: NgModule,
    args: [{}]
  }], function () {
    return [{
      type: NgProgressRouter
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NG_PROGRESS_ROUTER_CONFIG, NgProgressRouterModule };
