<ng-container [ngTemplateOutlet]="menuListTpl"
  [ngTemplateOutletContext]="{menuList: menu$ | async, parentRoute: [], level: 0}">
</ng-container>
<ng-template #menuListTpl let-menuList="menuList" let-parentRoute="parentRoute" let-level="level">
  <ul *ngFor="let menuItem of menuList; trackBy: trackByMenu" navAccordion class="sidemenu level-{{level}}" [class.submenu]="level>0">
<!--    <span>{{menuItem | json}}</span>-->

    <ng-container *ngIf="menuItem.type==='devider'" [ngTemplateOutlet]="deviderTpl" [ngTemplateOutletContext]="{item: menuItem, level: level}">
    </ng-container>

    <ng-template [ngIf]="menuList?.length">
      <li navAccordionItem class="menu-item" routerLinkActive="active" [route]="menuItem.route" [type]="menuItem.type">


        <a *ngIf="menuItem.type==='link'" class="menu-heading" [routerLink]="buildRoute(parentRoute.concat([menuItem.route]))" matRipple
          [matRippleDisabled]="!ripple">
          <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem, level: level}">
          </ng-container>
        </a>


        <a *ngIf="menuItem.type==='extLink'" class="menu-heading" [href]="menuItem.route" matRipple [matRippleDisabled]="!ripple">
          <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem, level: level}">
          </ng-container>
        </a>


        <a class="menu-heading" *ngIf="menuItem.type==='extTabLink'" [href]="menuItem.route" target="_blank" matRipple [matRippleDisabled]="!ripple">
          <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem, level: level}">
          </ng-container>
        </a>


        <button *ngIf="menuItem.type==='sub'" navAccordionToggle class="menu-heading menu-toggle" matRipple [matRippleDisabled]="!ripple">
          <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem, level: level}">
          </ng-container>
        </button>

        <ng-container [ngTemplateOutlet]="menuListTpl" [ngTemplateOutletContext]="{
            menuList: menuItem.children,
            parentRoute: parentRoute.concat([menuItem.route]),
            level: level + 1
            }">
        </ng-container>
      </li>
    </ng-template>

  </ul>
</ng-template>
<ng-template #linkTypeTpl let-item="item" let-level="level">

  <i-feather *ngIf="level===0" [name]="item.icon" class="sidebarIcon"></i-feather>

  <span *ngIf="item.tag"  class="menu-name">{{ item.tag }}</span>

  <span *ngIf="item.label" class="menu-label bg-{{ item.label.color }}">{{ item.label.value }}</span>


  <span *ngIf="item.badge" class="menu-badge bg-{{item.badge.color}}">{{item.badge.value}}</span>


  <mat-icon *ngIf="item.type !== 'link'" class="menu-caret">
    {{item.type === 'sub' ? 'keyboard_arrow_right' : 'launch'}}
  </mat-icon>

</ng-template>
<ng-template #deviderTpl let-item="item" let-level="level">
  <p class="menu-group-heading">{{item.tag}}</p>
</ng-template>
