<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <ng-container *ngFor="let menuItem of menu$ | async; let index = index;">
    <a *ngIf="menuItem.type === 'link'" mat-button [routerLink]="buildRoute([menuItem.route])"
      routerLinkActive="active">
      <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}"></ng-container>
    </a>

    <a *ngIf="menuItem.type === 'extLink'" mat-button [href]="menuItem.route">
      <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}"></ng-container>
    </a>

    <a *ngIf="menuItem.type === 'extTabLink'" mat-button [href]="menuItem.route" target="_blank">
      <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}"></ng-container>
    </a>

    <button *ngIf="menuItem.type === 'sub'" mat-button [matMenuTriggerFor]="submenu.menuPanel" routerLinkActive="active"
      [class.active]="menuStates[index].active">
      <ng-container [ngTemplateOutlet]="linkTypeTpl" [ngTemplateOutletContext]="{item: menuItem}"></ng-container>
      <app-topmenu-panel #submenu [items]="menuItem.children!" [parentRoute]="[menuItem.route]" [level]="1"
        (routeChange)="onRouteChange($event, index)">
      </app-topmenu-panel>
    </button>
  </ng-container>
</nav>

<mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>
<ng-template #linkTypeTpl let-item="item" let-level="level">
  <i-feather [name]="item.icon" class="menu-icon"></i-feather>
  <span class="menu-name">{{item.tag}}</span>

  <span *ngIf="item.label" class="menu-label bg-{{item.label.color}}">{{item.label.value}}</span>


  <span *ngIf="item.badge" class="menu-badge menu-badge-color">{{item.badge.value}}</span>


  <mat-icon *ngIf="item.type !== 'link'" class="menu-caret">
    {{item.type === 'sub' ? 'arrow_drop_down' : 'launch'}}
  </mat-icon>

</ng-template>
