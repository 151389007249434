<mat-menu class="topmenu-panel">
  <ng-container *ngFor="let item of items; let index = index;">
    <a *ngIf="item.type === 'link'" mat-menu-item [routerLink]="buildRoute(parentRoute.concat([item.route]))"
      routerLinkActive #rla="routerLinkActive" [class.active]="rla.isActive" (click)="onRouterLinkClick(rla)">
      {{ item.name  }}
    </a>

    <a *ngIf="item.type === 'extLink'" mat-menu-item [href]="item.route">
      <span class="menu-name">{{ item.name }}</span>
      <mat-icon>launch</mat-icon>
    </a>

    <a *ngIf="item.type === 'extTabLink'" mat-menu-item [href]="item.route" target="_blank">
      <span class="menu-name">{{ item.name }}</span>
      <mat-icon>launch</mat-icon>
    </a>

    <button *ngIf="item.type === 'sub'" mat-menu-item [matMenuTriggerFor]="submenu.menuPanel" routerLinkActive="active"
      [class.active]="menuStates[index].active">
      {{ item.name  }}
      <app-topmenu-panel #submenu [items]="item.children!" [parentRoute]="parentRoute.concat([item.route])"
        [level]="level + 1" (routeChange)="onRouteChange($event, index)">
      </app-topmenu-panel>
    </button>
  </ng-container>
</mat-menu>
