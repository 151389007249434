import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import {
    profileCreateActions,
    profileDetailsActions,
    profileListActions,
    profileUpdateActions,
} from '../../profiles/store/actions'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'
import { ClientsService } from '../services/clients.service'
import { clientCreateActions, clientDetailsActions, clientListActions, clientUpdateActions } from './actions'
import { ClientDto, Client } from '../models/client.model'
import { ProfileService } from '../../profiles/service/profile.service'
import { Profile } from '../../profiles/models/profiles.model'

export const clientsListEffect = createEffect(
    (actions$ = inject(Actions), clientsService = inject(ClientsService)) => {
        return actions$.pipe(
            ofType(clientListActions.requestClientList),
            switchMap(() => {
                return clientsService.clientList().pipe(
                    map((data: Client[]) => {
                        return clientListActions.requestClientListSuccess({ clients: data })
                    }),
                    catchError((error) => {
                        return of(clientListActions.requestClientListFailed(error))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const clientCreateEffect = createEffect(
    (actions$ = inject(Actions), clientsService = inject(ClientsService)) => {
        return actions$.pipe(
            ofType(clientCreateActions.requestClientCreate),
            switchMap(({ dto }) => {
                return clientsService.createClient(dto).pipe(
                    map((data: Client) => {
                        return clientCreateActions.requestClientCreateSuccess({ client: data })
                    }),
                    catchError((error) => {
                        return of(profileCreateActions.requestCreateProfileFailed(error.toString()))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const clientUpdateEffect = createEffect(
    (actions$ = inject(Actions), clientsService = inject(ClientsService)) => {
        return actions$.pipe(
            ofType(clientUpdateActions.requestClientUpdate),
            switchMap(({ dto }) => {
                return clientsService.updateClient(dto).pipe(
                    map(() => {
                        return clientUpdateActions.requestClientUpdateSuccess({ success: true })
                    }),
                    catchError((error) => {
                        return of(clientUpdateActions.requestClientUpdateFailed(error.toString()))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const clientDetailsByIdEffect = createEffect(
    (actions$ = inject(Actions), clientsService = inject(ClientsService)) => {
        return actions$.pipe(
            ofType(clientDetailsActions.requestClientById),
            switchMap(({ id }) => {
                return clientsService.clientDetails(id).pipe(
                    map((data: Client) => {
                        return clientDetailsActions.requestClientByIdSuccess({ client: data })
                    }),
                    catchError((error) => {
                        return of(clientDetailsActions.requestClientByIdFailed(error))
                    }),
                )
            }),
        )
    },
    { functional: true },
)
