import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'
import { SitesService } from '../services/sites.service'
import { siteCreateActions, siteDetailsActions, siteListActions, siteUpdateActions } from './actions'
import { Site } from '../models/sites.models'

export const sitesListEffect = createEffect(
    (actions$ = inject(Actions), sitesService = inject(SitesService)) => {
        return actions$.pipe(
            ofType(siteListActions.requestSiteList),
            switchMap(({ clientId }) => {
                return sitesService.siteList(clientId).pipe(
                    map((data: Site[]) => {
                        return siteListActions.requestSiteListSuccess({ sites: data })
                    }),
                    catchError((error) => {
                        return of(siteListActions.requestSiteListFailed(error))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const siteCreateEffect = createEffect(
    (actions$ = inject(Actions), sitesService = inject(SitesService)) => {
        return actions$.pipe(
            ofType(siteCreateActions.requestSiteCreate),
            switchMap(({ dto }) => {
                return sitesService.createSite(dto).pipe(
                    map((data: Site) => {
                        return siteCreateActions.requestSiteCreateSuccess({ site: data })
                    }),
                    catchError((error) => {
                        return of(siteListActions.requestSiteListFailed(error.toString()))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const siteUpdateEffect = createEffect(
    (actions$ = inject(Actions), sitesService = inject(SitesService)) => {
        return actions$.pipe(
            ofType(siteUpdateActions.requestSiteUpdate),
            switchMap(({ dto }) => {
                return sitesService.updateSite(dto).pipe(
                    map(() => {
                        return siteUpdateActions.requestSiteUpdateSuccess({ success: true })
                    }),
                    catchError((error) => {
                        return of(siteUpdateActions.requestSiteUpdateFailed(error.toString()))
                    }),
                )
            }),
        )
    },
    { functional: true },
)

export const siteDetailsByIdEffect = createEffect(
    (actions$ = inject(Actions), sitesService = inject(SitesService)) => {
        return actions$.pipe(
            ofType(siteDetailsActions.requestSiteById),
            switchMap(({ id }) => {
                return sitesService.siteDetails(id).pipe(
                    map((data: Site) => {
                        return siteDetailsActions.requestSiteByIdSuccess({ site: data })
                    }),
                    catchError((error) => {
                        return of(siteDetailsActions.requestSiteByIdFailed(error))
                    }),
                )
            }),
        )
    },
    { functional: true },
)
