<div class="customizer-header primaryColorBG">
  <p class="customizer-text f-s-18 f-w-500">Theme Config</p>
</div>
<div id="customizer">
  <ng-scrollbar [style.height]="listMaxHeight + 'px'" visibility="hover">
    <form [formGroup]="form" class="customizer-form">

      <div class="m-l-8">
        <div class="customizer-title">
          <h3>Theme</h3>
          <span>Switch theme mode to Light, Dark or Auto</span>
        </div>
        <mat-radio-group formControlName="theme">
          <mat-radio-button class="m-x-4" value="light" color="primary">Light</mat-radio-button>
          <mat-radio-button class="m-x-4" value="dark" color="primary">Dark</mat-radio-button>
        </mat-radio-group>
      </div>
      <mat-divider inset class="m-x-0 m-y-24"></mat-divider>

    </form>
  </ng-scrollbar>
</div>
