import { Component, Input, ViewEncapsulation } from '@angular/core'
import { Menu, MenuService } from '@core'
import { MatIconModule } from '@angular/material/icon'
import { NavAccordionToggleDirective } from './nav-accordion-toggle.directive'
import { MatRippleModule } from '@angular/material/core'
import { RouterLinkActive, RouterLink } from '@angular/router'
import { NavAccordionItemDirective } from './nav-accordion-item.directive'
import { NavAccordionDirective } from './nav-accordion.directive'
import { NgTemplateOutlet, AsyncPipe, CommonModule } from '@angular/common'
import { FeatherModule } from 'angular-feather'
import { Observable } from 'rxjs'

@Component({
    selector: 'app-sidemenu',
    templateUrl: './sidemenu.component.html',
    styleUrls: ['./sidemenu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        NgTemplateOutlet,
        NavAccordionDirective,
        NavAccordionItemDirective,
        RouterLinkActive,
        MatRippleModule,
        RouterLink,
        NavAccordionToggleDirective,
        MatIconModule,
        AsyncPipe,
        FeatherModule,
        CommonModule,
    ],
})
export class SidemenuComponent {
    // Note: Ripple effect make page flashing on mobile
    @Input() ripple = false

    menu$!: Observable<Menu[]>

    buildRoute!: (routeArr: string[]) => string

    constructor(private menu: MenuService) {
        this.menu$ = this.menu.getAll()
        this.buildRoute = this.menu.buildRoute
    }

    trackByMenu(index: number, item: any): number {
        return item // Use the unique "id" property for tracking
    }
}
