import { Injectable } from '@angular/core'
import { Observable, Subscriber } from 'rxjs'
import {
    PostgrestMaybeSingleResponse,
    PostgrestResponse,
    PostgrestSingleResponse,
    SupabaseClient,
} from '@supabase/supabase-js'
import { SupabaseService } from '@core/services/supabase.service'
import { Site, SiteCreateDto, SiteUpdateDto } from '../models/sites.models'

@Injectable({
    providedIn: 'root',
})
export class SitesService {
    private supabase!: SupabaseClient

    constructor(private supabaseService: SupabaseService) {
        this.supabase = this.supabaseService.getClient()
    }

    siteDetails(id: number): Observable<Site> {
        return new Observable((subscriber: Subscriber<Site>) => {
            this.supabase
                .from('sites')
                .select('*')
                .eq('id', id)
                .single()
                .then((result: PostgrestMaybeSingleResponse<Site>) => subscriber.next(result.data!))
        })
    }

    siteList(client_id: number): Observable<Site[]> {
        return new Observable((subscriber: Subscriber<Site[]>) => {
            this.supabase
                .from('sites')
                .select('*')
                .eq('client_id', client_id)
                .then((result: PostgrestResponse<Site>) => subscriber.next(result.data!))
        })
    }

    createSite(site: SiteCreateDto): Observable<Site> {
        return new Observable((subscriber: Subscriber<Site>) => {
            try {
                this.supabase
                    .from('sites')
                    .insert(site)
                    .select()
                    .then((result: PostgrestResponse<Site>) => {
                        if (result.error != null) {
                            subscriber.error(result.error.message)
                        }
                        if (result.data?.length) {
                            subscriber.next(result.data![0])
                        }
                    })
            } catch (e) {
                return subscriber.error('Could not create site')
            }
        })
    }

    updateSite(site: SiteUpdateDto): Observable<boolean> {
        return new Observable((subscriber: Subscriber<boolean>) => {
            try {
                const { id, ...siteData } = site

                const query = this.supabase
                    .from('sites')
                    .update({ ...siteData })
                    .eq('id', id)

                query.then((result: PostgrestSingleResponse<null>) => {
                    if (result.error != null) {
                        subscriber.error(result.error.message)
                    }
                    if (result.status === 204) {
                        subscriber.next(true)
                    }
                })
            } catch (e) {
                console.warn('the error')
                console.warn(e)
                return subscriber.error('Could not update site')
            }
        })
    }
}
