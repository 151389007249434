import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'

import { Observable } from 'rxjs'
import { SupabaseAuthService } from '../services/supabase-auth.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard {
    constructor(
        private authService: SupabaseAuthService,
        private router: Router,
    ) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.canActivate(route, state)
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | Observable<boolean> | Promise<boolean> {
        console.log('i catch it')
        return !!this.authService.session
        // if (data.session?.access_token != null) {
        //     return true
        // } else {
        //     // Redirect to the login page if the user is not authenticated
        //     this.router.navigate(['/auth/login'])
        //     return false
        // }
    }
}
