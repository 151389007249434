import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core'
import { Router, RouterLink } from '@angular/router'
import { debounceTime, tap } from 'rxjs/operators'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { User } from '@supabase/supabase-js'
import { SupabaseAuthService } from '../../core/services/supabase-auth.service'
import { NgIf } from '@angular/common'

@Component({
    selector: 'app-user',
    encapsulation: ViewEncapsulation.None,
    template: `
        <button class="r-full" mat-button [matMenuTriggerFor]="menu">
            <!--            <img matButtonIcon class="avatar r-full" [src]="" width="24" alt="avatar" />-->
            <span *ngIf="!!user" class="m-x-8">{{ user.email }}</span>
        </button>

        <mat-menu #menu="matMenu" class="profileMenu">
            <button routerLink="/profile/overview" mat-menu-item>
                <mat-icon class="material-icons-outlined">account_circle</mat-icon>
                <span class="f-s-14">{{ 'profile' }}</span>
            </button>
            <button routerLink="/profile/settings" mat-menu-item>
                <mat-icon class="material-icons-outlined">edit</mat-icon>
                <span class="f-s-14">{{ 'edit_profile' }}</span>
            </button>
            <button mat-menu-item (click)="restore()">
                <mat-icon class="material-icons-outlined">restore</mat-icon>
                <span class="f-s-14">{{ 'restore_defaults' }}</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon class="material-icons-outlined">exit_to_app</mat-icon>
                <span class="f-s-14">{{ 'logout' }}</span>
            </button>
        </mat-menu>
    `,
    styles: [
        `
            .avatar {
                width: 24px;
                height: 24px;
            }
            .profileMenu {
                background-color: #ffffff !important;
                border: 1px solid #e4e1ec;
            }
            .dark {
                .profileMenu {
                    background-color: #1a1a1a !important;
                    border: 1px solid #e1e1e1;
                }
            }
        `,
    ],
    standalone: true,
    imports: [MatButtonModule, MatMenuModule, RouterLink, MatIconModule, NgIf],
})
export class UserComponent implements OnInit {
    user!: User

    constructor(
        private router: Router,
        private auth: SupabaseAuthService,
        private cdr: ChangeDetectorRef,
    ) {}

    async ngOnInit(): Promise<void> {
        const { data } = await this.auth.session
        this.user = data.session?.user!
    }

    async logout() {
        await this.auth.signOut()
    }

    restore() {
        window.location.reload()
    }
}
