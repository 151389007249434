import { Actions, createEffect, ofType } from '@ngrx/effects'
import { inject } from '@angular/core'
import { catchError, switchMap } from 'rxjs/operators'
import { map, of } from 'rxjs'
import { PostsService } from '../services/posts.service'
import { postListActions } from './actions'
import { Post } from '../models/post.model'

export const postListEffect = createEffect(
    (actions$ = inject(Actions), postService = inject(PostsService)) => {
        return actions$.pipe(
            ofType(postListActions.requestPostList),
            switchMap(() => {
                return postService.postList().pipe(
                    map((data: Post[]) => {
                        return postListActions.requestPostListSuccess({ posts: data })
                    }),
                    catchError((error) => {
                        return of(postListActions.requestPostListFailed(error))
                    }),
                )
            }),
        )
    },
    { functional: true },
)
